import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
  collection,
  onSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../utils/init-firebase";
import { Formik } from "formik";
import { FiEdit, FiPlus } from "react-icons/fi";
import * as Yup from "yup";
import "yup-phone";
import { settingsRef } from "../../functions/globalFunctions";
import {
  InputControl,
  RadioGroupControl,
  SelectControl,
  SubmitButton,
  TextareaControl,
  ResetButton,
  CheckboxSingleControl,
  CheckboxControl,
} from "formik-chakra-ui";
import {
  Box,
  useDisclosure,
  useToast,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Center,
  IconButton,
  Wrap,
  WrapItem,
  Button,
  Heading,
  useColorModeValue,
  Input,
  HStack,
  Stack,
  DrawerFooter,
  Image,
  Text,
  FormControl,
  Radio,
  RadioGroup,
  Tooltip,
  VStack,
  Divider,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { DatePickerField } from "../../components/DatePicker";
import NavButton from "../../components/Navigation/NavButton";
import DrawerButton from "../../components/Navigation/DrawerButton";
import { states } from "../../assets/states";
import { Select } from "chakra-react-select";

const targetLenderFallback = [
  "UWM",
  "Cardinal",
  "Homepoint",
  "Flagstar",
  "Other",
];
const otherLenderFallback = [
  "Plaza",
  "Angel Oak",
  "Deephaven",
  "Brokers Advantage",
  "ACRA",
  "NewFi",
  "REMN",
  "Change Wholesale",
  "Champions Funding",
  "United AG",
  "Amerant Wholesale",
  "Orion Star",
  "Franklin Citizens",
  "HomeExpress",
  "Spring EQ",
  "Equity Prime Mortgage",
  "Neptune Wholesale",
  "Freedom",
  "New Rez / Caliber",
  "Mortgage Lit",
  "AFR",
  "FLC Bank",
  "The Loan Store",
  "Carrington Mortgage",
  "Finance of America",
  "Other",
];
const lenderReasonFallback = [
  "Rate",
  "LTV",
  "Service",
  "Unique Scenario",
  "AE",
  "Other",
];
export default function SubmissionDrawer(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const adaptiveGreyBorder = useColorModeValue("gray.300", "gray.600");
  const adaptiveGreyText = useColorModeValue("gray.500", "gray.500");
  const datepickerClass = useColorModeValue(
    "chakra-input css-1c6j008",
    "chakra-input css-xpongc"
  );
  const { currentUser, Level1 } = useAuth();
  const { users, lenders } = useAuth();

  const formRef = useRef();

  // const [Level1, setLevel1] = useState(false)

  // console.log(Level1)

  const [selectedLO, setSelectedLO] = useState({
    label: currentUser.displayName,
    value: currentUser.uid,
    photoURL: currentUser.photoURL,
    email: currentUser.email,
  });
  const [selectedLOA, setSelectedLOA] = useState();
  const [costOfCROptions, setCostofCROptions] = useState();
  const [loanTypeOptions, setLoanTypeOptions] = useState();
  const [otherLoanTypeOptions, setOtherLoanTypeOptions] = useState();
  const [productOptions, setProductOptions] = useState();
  const [lenderOptions, setLenderOptions] = useState();
  const [otherLenderOptions, setOtherLenderOptions] = useState();
  const [lenderReasonOptions, setLenderReasonOptions] = useState();
  const [subBannerText, setSubBannerText] = useState();
  const toast = useToast();
  const firstField = React.useRef();
  const action = props.action;
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Initalize settings
  // "Other/Non-QM" is hard-coded in because the 'Other Loan' open field is dependent on it
  useEffect(async () => {
    const unsub = onSnapshot(settingsRef, (settingsSnap) => {
      if (settingsSnap.exists()) {
        setCostofCROptions(
          settingsSnap.data()["Cost For Credit Report Options"]
            ? settingsSnap.data()["Cost For Credit Report Options"]
            : ["135", "150", "200"]
        );
        setLoanTypeOptions(
          settingsSnap.data()["Loan Type Options"]
            ? [...settingsSnap.data()["Loan Type Options"], "Other/Non-QM"]
            : ["VA", "Conventional", "FHA", "Other/Non-QM"]
        );
        setOtherLoanTypeOptions(
          settingsSnap.data()["Other Loan Type Options"]
            ? [...settingsSnap.data()["Other Loan Type Options"]]
            : ["VA", "Conventional", "FHA", "Other/Non-QM"]
        );
        setProductOptions(
          settingsSnap.data()["Product Options"]
            ? settingsSnap.data()["Product Options"]
            : [
                "Conventional",
                "FHA",
                "VA",
                "HELOC",
                "USDA",
                "Reverse",
                "Non-QM",
              ]
        );
        setLenderOptions(
          settingsSnap.data()["Target Lenders"]
            ? [...settingsSnap.data()["Target Lenders"], "Other"]
            : targetLenderFallback
        );
        setOtherLenderOptions(
          settingsSnap.data()["Other Lenders"]
            ? settingsSnap.data()["Other Lenders"]
            : otherLenderFallback
        );
        setLenderReasonOptions(
          settingsSnap.data()["Why did you select this Lender options"]
            ? [
                ...settingsSnap.data()[
                  "Why did you select this Lender options"
                ],
                "Other",
              ]
            : lenderReasonFallback
        );
        setSubBannerText(
          settingsSnap.data()["Sub Banner Text"]
            ? settingsSnap.data()["Sub Banner Text"]
            : "Please keep in mind our LC Team communicates through the HUB.  We appreciate you doing the same."
        );
      } else {
        setCostofCROptions(["135", "150", "200"]);
        setLoanTypeOptions(["VA", "Conventional", "FHA", "Other/Non-QM"]);
        setProductOptions([
          "Conventional",
          "FHA",
          "VA",
          "HELOC",
          "USDA",
          "Reverse",
          "Non-QM",
        ]);
        setSubBannerText(
          "Please keep in mind our LC Team communicates through the HUB.  We appreciate you doing the same."
        );
        setLenderOptions(targetLenderFallback);
        setOtherLenderOptions(otherLenderFallback);
        setLenderOptions(lenderReasonFallback);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  let initialValues = [];
  if (props.initialValues == null) {
    initialValues = {
      "Purchase or Refi": "",
      "First Name": "",
      "Last Name": "",
      MI: "",
      Location: "",
      "Texas Refi Conversion": "",
      "Property Type": "",
      "Cost for Credit Report": "",
      "First-Time Home Buyer": "No",
      "New Construction": "No",
      "Down Payment %": "",
      "Credit Score": "",
      "Loan Type": "",
      "Other Loan Type": "",
      "Accounted for Seller's Previous Recording Date": "",
      "Subject to FHA Anti-Flipping Rules": "",
      "Loan Purpose": "",
      "Cash Out Amount": "",
      "Target Rate": "",
      Term: "",
      "Target Lender": "",
      "Other Lender": "",
      "Waive UW Fee": "",
      "LP or BP": "",
      "Reason for BP": "",
      Competitor: "",
      "BP Confirmed": false,
      "Cash to Close": "",
      "Cash to Close Amount": "",
      "Anticipated Funds to Close": "",
      "Closing Date": "",
      "App Date": "",
      "Loan Amount": "",
      "Priced Loan Today": "",
      "Are Escrows Being Waived?": "",
      "Escrows Waived": "",
      "Opt Out Of SimplyIOA Quote": false,
      "Opt Out Of HOI Quote": false,
      "HOI Agent": "",
      "Relative Entered Into Arive": false,
      Relative: {
        Name: "",
        Phone: "",
        Address: "",
        Relation: "",
      },
      "Title Company": "",
      "Title Company Details": {
        Name: "",
        "Point of Contact": "",
        Phone: "",
        Email: "",
      },
      "Community Property State": "",
      "NBS Liabilities Accounted For": "",
      "File Location": "",
      "Mail Away/POA": "",
      "Order Appraisal": "",
      "POS Docs Received": "",
      Notes: "",
      Lender: "",
      lenderID: "",
      "Loan #": "",
      "HOI Quote/Amount": "",
      Rate: "",
      "Floated or Locked": "",
      "Comp Code": "",
      Product: "",
      Description: "",
      "Ownership/Vesting Changing": "",
      "Asked the LO about an Exact Rate": false,
      "GUS has been completed": false,
      "Findings have been released to the Lender": false,
      "W2 Income": "",
      "VOE fee disclosed": false,
      "Income Analysis Completed": "",
      "Income Analysis Reason": "",
      "What POS Docs have been received": "",
      "What POS Docs are outstanding": "",
      "Loan Narrative": "",
      "Assets and Income": "",
      Gotchas: "",
      "Appraisal Contact": {
        Name: "",
        Phone: "",
        Email: "",
      },
      "Title Retired": "",
      "Title Allowed To Be Retired After Closing": "",
      "Why did you select this Lender?": "",
      "(Other) Why did you select this Lender?": "",
      "LO Feedback Given": false,
      "Lender Transfer or First Submission": "",
      "Appraisal Transfer": "",
    };
  } else initialValues = props.initialValues;

  const onSubmission = async (values) => {
    try {
      const thisDocRef = doc(db, "Clients", props.docID);
      await updateDoc(thisDocRef, {
        "Loan Details": values,
        "Date Completed": serverTimestamp(),
        Status: "Completed",
        "Last Edited By": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
      });
      toast({
        title: "Client Submitted",
        description:
          values["First Name"] +
          " " +
          values["Last Name"] +
          " successfully Submitted!",
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const onEdit = async (values, newStatus, reason, otherReason) => {
    try {
      const timestamp = serverTimestamp();
      const thisDocRef = doc(db, "Clients", props.docID);
      if (newStatus && reason) {
        let thisReason = reason;
        if (reason == "Other") {
          thisReason = otherReason;
        }
        await updateDoc(thisDocRef, {
          "Loan Details": values,
          "Date Edited": timestamp,
          [`Date ${newStatus}`]: timestamp,
          [`Reason ${newStatus}`]: thisReason,
          "Last Edited By": {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
          Status: newStatus,
        });
      } else if (newStatus) {
        await updateDoc(thisDocRef, {
          "Loan Details": values,
          "Date Edited": timestamp,
          [`Date ${newStatus}`]: timestamp,
          "Last Edited By": {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
          Status: newStatus,
        });
      } else {
        await updateDoc(thisDocRef, {
          "Loan Details": values,
          "Date Edited": timestamp,
          "Last Edited By": {
            Email: currentUser.email,
            Name: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
        });
      }

      toast({
        title: `Client Updated!`,
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const onAdd = async (values) => {
    try {
      const newDocRef = doc(collection(db, "Clients"));
      await setDoc(newDocRef, {
        "Loan Details": values,
        "Loan Officer": {
          Name: selectedLO.label,
          photoURL: selectedLO.photoURL,
          Email: selectedLO.email,
          uid: selectedLO.value,
        },
        "File Creator": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
        "Date Added": serverTimestamp(),
        id: newDocRef.id,
        Status: "New",
        "Last Edited By": {
          Email: currentUser.email,
          Name: currentUser.displayName,
          photoURL: currentUser.photoURL,
          uid: currentUser.uid,
        },
        LOA: {
          Name: selectedLOA ? selectedLOA.label : "",
          photoURL: selectedLOA ? selectedLOA.photoURL : "",
          Email: selectedLOA ? selectedLOA.email : "",
          uid: selectedLOA ? selectedLOA.value : "",
        },
        LC: {
          Email: "",
          Name: "",
          photoURL: "",
          uid: "",
        },
        CSC: {
          Email: "",
          Name: "",
          photoURL: "",
          uid: "",
        },
        TC: {
          Email: "",
          Name: "",
          photoURL: "",
          uid: "",
        },
      });

      if (values.Notes != "") {
        const newNoteRef = doc(
          collection(db, "Clients", newDocRef.id, "Notes")
        );
        await setDoc(newNoteRef, {
          id: newNoteRef.id,
          message: values.Notes,
          parentID: newDocRef.id,
          timestamp: serverTimestamp(),
          user: {
            email: currentUser.email,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
            uid: currentUser.uid,
          },
        });
      }
      /*             if(!window.location.hostname === 'localhost'){
                await incrementSetupQueueCount()
            } */
      toast({
        title: "Client Added",
        description:
          values["First Name"] +
          " " +
          values["Last Name"] +
          " successfully added!",
        status: "success",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
      onClose();
    } catch (err) {
      toast({
        description: err,
        status: "error",
        isClosable: true,
        duration: 5000,
        position: "top",
      });
    }
  };

  const addValidationObj = Yup.object({
    "Purchase or Refi": Yup.string().required("Required"),
    "First Name": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Last Name": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    MI: Yup.string().max(1, "1 Character Max"),
    "Property Type": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Cost for Credit Report": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "First-Time Home Buyer": Yup.string(),
    "New Construction": Yup.string(),
    "Down Payment %": Yup.number()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.typeError("Numbers Only").required("Required"),
        otherwise: (schema) => schema.typeError("Numbers Only"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.typeError("Numbers Only").required("Required"),
        otherwise: (schema) => schema.typeError("Numbers Only"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Credit Score": Yup.number()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.typeError("Numbers Only").required("Required"),
        otherwise: (schema) => schema.typeError("Numbers Only"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.typeError("Numbers Only").required("Required"),
        otherwise: (schema) => schema.typeError("Numbers Only"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.typeError("Numbers Only").required("Required"),
        otherwise: (schema) => schema.typeError("Numbers Only"),
      }),
    "Loan Type": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      }),
    "Other Loan Type": Yup.string().when(["Loan Type"], {
      is: "Other/Non-QM",
      then: (schema) => schema.required("Required"),
    }),
    "Accounted for Seller's Previous Recording Date": Yup.string().when(
      ["Loan Type"],
      {
        is: "FHA",
        then: (schema) => schema.required("Required"),
      }
    ),
    "Subject to FHA Anti-Flipping Rules": Yup.string().when(["Loan Type"], {
      is: "FHA",
      then: (schema) => schema.required("Required"),
    }),
    "Loan Purpose": Yup.string().when(["Purchase or Refi"], {
      is: "Refi",
      then: (schema) => schema.required("Required"),
    }),
    "Cash Out Amount": Yup.number()
      .typeError("Numbers Only")
      .when(["Loan Purpose"], {
        is: "Cash Out",
        then: (schema) => schema.required("Required"),
      }),
    "Target Rate": Yup.number()
      .typeError("Numbers Only")
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    Term: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      }),
    "Target Lender": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Other Lender": Yup.string().when(["Target Lender"], {
      is: "Other",
      then: (schema) => schema.required("Required"),
    }),
    "Waive UW Fee": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      }),
    "LP or BP": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Order Appraisal": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      }),
    "Reason for BP": Yup.string().when(["LP or BP"], {
      is: "BP",
      then: (schema) => schema.required("Required"),
    }),
    Competitor: Yup.string().when(["Reason for BP"], {
      is: "Competitive Scenario",
      then: (schema) => schema.required("Required"),
    }),
    "Anticipated Funds to Close": Yup.number()
      .typeError("Numbers Only")
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      }),
    "Priced Loan Today": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Are Escrows Being Waived?": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Escrows Waived": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: Yup.string().when(["Are Escrows Being Waived?"], {
          is: "Yes",
          then: (schema) => schema.required("Required"),
        }),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: Yup.string().when(["Are Escrows Being Waived?"], {
          is: "Yes",
          then: (schema) => schema.required("Required"),
        }),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: Yup.string().when(["Are Escrows Being Waived?"], {
          is: "Yes",
          then: (schema) => schema.required("Required"),
        }),
      }),
    Relative: Yup.object().when(["Loan Type"], {
      is: `VA`,
      then: Yup.object().when(["Relative Entered Into Arive"], {
        is: false,
        then: Yup.object({
          Name: Yup.string().required("Required"),
          Phone: Yup.string().required("Required"),
          Address: Yup.string().required("Required"),
          Relation: Yup.string().required("Required"),
        }),
      }),
    }),
    "HOI Agent": Yup.string().when(["Opt Out Of HOI Quote"], {
      is: true,
      then: (schema) => schema.required("Required"),
    }),

    /*         'Title Company': Yup.string()
            .when(['Purchase or Refi'], {
                is: 'Purchase',
                then: (schema) => schema.required('Required')
            })
            .when(['Purchase or Refi'], {
                is: 'Refi',
                then: (schema) => schema.required('Required')
            }), */
    /*         'Title Company Details': Yup.object().when('Title Company',({
            is: 'Enter Title Company', 
            then: Yup.object({
                'Name': Yup.string().required('Required'), 
                'Point of Contact': Yup.string().required('Required'), 
                'Phone': Yup.number().required('Required'), 
                'Email': Yup.string().email('Must be an email address').required('Required'), 
            })
          
        })),  */
    "Closing Date": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      }),
    "Loan Amount": Yup.number()
      .typeError("Numbers Only")
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) =>
          schema.required("Required").min(50000, "Minimum $50,000"),
      }),
    "POS Docs Received": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Mail Away/POA": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    Description: Yup.string().when(["Purchase or Refi"], {
      is: "Custom",
      then: (schema) => schema.required("Required"),
    }),
    "Community Property State": Yup.string()
      .when(["Loan Type"], {
        is: "VA",
        then: (schema) => schema.required("Required"),
      })
      .when(["Loan Type"], {
        is: "FHA",
        then: (schema) => schema.required("Required"),
      }),
    "NBS Liabilities Accounted For": Yup.string().when(
      ["Community Property State"],
      {
        is: "Yes",
        then: (schema) => schema.required("Required"),
      }
    ),
    Location: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Texas Refi Conversion": Yup.string().when(["Purchase or Refi"], {
      is: "Refi",
      then: (schema) =>
        schema.when(["Location"], {
          is: "TX",
          then: (schema) => schema.required("Required"),
        }),
    }),
    "Ownership/Vesting Changing": Yup.string().when(["Purchase or Refi"], {
      is: "Refi",
      then: (schema) => schema.required("Required"),
    }),

    /*  'Credit Valid Until': Yup.string().required('Required'),
        'Deadline for HOI': Yup.string().required('Required'),
        'Compensation Type': Yup.string().required('Required'),
        'Loan Key/Cardinal': Yup.string().required('Required'),
        'Order Appraisal': Yup.string().required('Required'),
        'Called Listing & Buyer Agent': Yup.boolean().equals([true], 'You must call the agents'),
        'Listing Agent': Yup.object({
            'Name': Yup.string().required('Required'),
            'Phone': Yup.string().required('Required'),
            'Email': Yup.string().required('Required'),
        }),
        'Buying Agent': Yup.object({
            'Name': Yup.string().required('Required'),
            'Phone': Yup.string().required('Required'),
            'Email': Yup.string().required('Required'),
        }), */
    "Title Retired": Yup.string().when(["Property Type"], {
      is: "Manufactured",
      then: (schema) => schema.required("Required"),
    }),
    "Title Allowed To Be Retired After Closing": Yup.string().when(
      ["Title Retired"],
      {
        is: "No",
        then: (schema) => schema.required("Required"),
      }
    ),
    "Why did you select this Lender?": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "(Other) Why did you select this Lender?": Yup.string().when(
      ["Why did you select this Lender?"],
      {
        is: "Other",
        then: (schema) => schema.required("Required"),
      }
    ),
    "Lender Transfer or First Submission": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Appraisal Transfer": Yup.string().when(
      ["Lender Transfer or First Submission"],
      {
        is: "Lender Transfer",
        then: (schema) => schema.required("Required"),
      }
    ),
  });

  const level1ValidationObj = Yup.object({
    "Loan Narrative": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) =>
          schema.required("Required").min(20, "Minimum 20 Characters"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) =>
          schema.required("Required").min(20, "Minimum 20 Characters"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) =>
          schema.required("Required").min(20, "Minimum 20 Characters"),
      }),
    "Assets and Income": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    Gotchas: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
  });

  const submitValidationObj = Yup.object({
    Lender: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Loan #": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "HOI Quote/Amount": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    Rate: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Floated or Locked": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Comp Code": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    Product: Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "BP Confirmed": Yup.boolean().when(["LP or BP"], {
      is: "BP",
      then: (schema) => schema.isTrue("Required"),
    }),
    "Asked the LO about an Exact Rate": Yup.boolean().when(["Target Lender"], {
      is: "UWM",
      then: (schema) => schema.isTrue("Required"),
    }),
    "W2 Income": Yup.string().when(["Target Lender"], {
      is: "UWM",
      then: (schema) => schema.required("Required"),
    }),
    "VOE fee disclosed": Yup.boolean().when(["W2 Income"], {
      is: "Yes",
      then: (schema) => schema.isTrue("Required"),
    }),

    "GUS has been completed": Yup.boolean().when(["Loan Type"], {
      is: "USDA",
      then: (schema) => schema.isTrue("Required"),
    }),
    "Findings have been released to the Lender": Yup.boolean().when(
      ["Loan Type"],
      {
        is: "USDA",
        then: (schema) => schema.isTrue("Required"),
      }
    ),
    "Income Analysis Completed": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "Income Analysis Reason": Yup.string().when(["Income Analysis Completed"], {
      is: "No",
      then: (schema) => schema.required("Required"),
    }),
    "What POS Docs have been received": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "What POS Docs are outstanding": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
    "App Date": Yup.string()
      .when(["Purchase or Refi"], {
        is: "Purchase",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "Refi",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "TBD",
        then: (schema) => schema.required("Required"),
      })
      .when(["Purchase or Refi"], {
        is: "HELOC",
        then: (schema) => schema.required("Required"),
      }),
  });

  const submitValTitleCompany = Yup.object({
    "Title Company Details": Yup.object()
      .when("Purchase or Refi", {
        is: "Purchase",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
          Phone: Yup.number().required("Required"),
          Email: Yup.string()
            .email("Must be an email address")
            .required("Required"),
        }),
      })
      .when("Purchase or Refi", {
        is: "Refi",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
          Phone: Yup.number().required("Required"),
          Email: Yup.string()
            .email("Must be an email address")
            .required("Required"),
        }),
      })
      .when("Purchase or Refi", {
        is: "HELOC",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
          Phone: Yup.number().required("Required"),
          Email: Yup.string()
            .email("Must be an email address")
            .required("Required"),
        }),
      }),
  });

  const addValTitleCompany = Yup.object({
    "Title Company Details": Yup.object()
      .when("Purchase or Refi", {
        is: "Purchase",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
        }),
      })
      .when("Purchase or Refi", {
        is: "Refi",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
        }),
      })
      .when("Purchase or Refi", {
        is: "HELOC",
        then: Yup.object({
          Name: Yup.string().required("Required"),
          "Point of Contact": Yup.string().required("Required"),
        }),
      }),
  });

  const submitValidation = submitValidationObj
    .concat(addValidationObj)
    .concat(submitValTitleCompany);
  const addValidation = Level1
    ? addValidationObj.concat(level1ValidationObj).concat(addValTitleCompany)
    : addValidationObj.concat(addValTitleCompany);

  return (
    <>
      {action == "Add" && (
        <>
          {props.isNavButton ? (
            <NavButton
              onOpen={onOpen}
              icon={FiPlus}
              name="Add Client"
              navSize={props.navSize}
              isChild={props.isChild}
              isFirstChild={props.isFirstChild}
              isLastChild={props.isLastChild}
            ></NavButton>
          ) : props.isDrawerButton ? (
            <DrawerButton
              onOpen={onOpen}
              icon={FiPlus}
              name="Add Client"
              navSize={props.navSize}
              isChild={props.isChild}
              isFirstChild={props.isFirstChild}
              isLastChild={props.isLastChild}
            ></DrawerButton>
          ) : (
            <Button rightIcon={<FiPlus />} colorScheme="teal" onClick={onOpen}>
              {action} Client
            </Button>
          )}
        </>
      )}
      {action == "Submit" && (
        <IconButton icon={<FiEdit />} colorScheme="teal" onClick={onOpen} />
      )}
      <Drawer
        isOpen={isOpen}
        placement="left"
        initialFocusRef={firstField}
        onClose={onClose}
        size="xl"
      >
        <DrawerOverlay />
        <Formik
          initialValues={initialValues}
          onSubmit={action == "Add" ? onAdd : onSubmission}
          validationSchema={action == "Add" ? addValidation : submitValidation}
          innerRef={formRef}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <Box
              borderWidth={{ base: "none", md: "1px" }}
              rounded="lg"
              shadow={{ base: "none", md: "1px 1px 3px rgba(0,0,0,0.3)" }}
              maxWidth={850}
              p={{ base: "0", md: "5" }}
              m="10px auto"
              as="form"
              onSubmit={handleSubmit}
            >
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  {action == "Add" ? (
                    <>
                      <Heading size="md">Add Client</Heading>
                      {subBannerText && (
                        <Text fontSize={"sm"} as="em">
                          {subBannerText}
                        </Text>
                      )}
                    </>
                  ) : (
                    <>Submit Loan</>
                  )}
                </DrawerHeader>
                <DrawerBody>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    justifyContent="space-between"
                    mb={5}
                  >
                    <RadioGroupControl
                      radioGroupProps={{ style: { fontSize: "40px" } }}
                      name="Purchase or Refi"
                      mb={{ base: "5", md: "0" }}
                    >
                      <Radio value="Purchase">Purchase</Radio>
                      <Radio value="Refi">Refi</Radio>
                      <Radio value="HELOC">HELOC</Radio>
                      <Radio value="Custom">Custom</Radio>
                      <Radio value="TBD">TBD</Radio>
                    </RadioGroupControl>
                    {action == "Add" && (
                      <>
                        <Flex
                          minWidth="300px"
                          maxWidth="400px"
                          flexDir={"column"}
                        >
                          <HStack mb={1}>
                            <Center>
                              <Text fontWeight="bold" pr="2">
                                LO&nbsp;&nbsp;&nbsp;
                              </Text>
                            </Center>
                            <FormControl>
                              <Select
                                options={users}
                                onChange={(e) => setSelectedLO(e)}
                                value={selectedLO}
                                closeMenuOnSelect={true}
                                selectedOptionColor="green"
                                classNamePrefix={"lo-select"}
                              />
                            </FormControl>
                          </HStack>
                          <HStack>
                            <Center>
                              <Text fontWeight="bold" pr="2">
                                LOA
                              </Text>
                            </Center>
                            <FormControl>
                              <Select
                                options={users}
                                onChange={(e) => setSelectedLOA(e)}
                                value={selectedLOA}
                                closeMenuOnSelect={true}
                                selectedOptionColor="green"
                                classNamePrefix={"loa-select"}
                                placeholder="Select LOA"
                              />
                            </FormControl>
                          </HStack>
                        </Flex>
                      </>
                    )}
                  </Flex>

                  {values["Purchase or Refi"] != "Custom" &&
                    values["Purchase or Refi"] != "TBD" && (
                      <Flex
                        direction={{ base: "column", md: "row" }}
                        justifyContent="space-between"
                        mb={5}
                      >
                        <RadioGroupControl
                          radioGroupProps={{ style: { fontSize: "40px" } }}
                          name="Lender Transfer or First Submission"
                          label="Is this a First Submission or a Lender Transfer?"
                          mb={{ base: "5", md: "0" }}
                        >
                          <Radio value="First Submssion">
                            First Submission
                          </Radio>
                          <Radio value="Lender Transfer">Lender Transfer</Radio>
                        </RadioGroupControl>
                        {values["Lender Transfer or First Submission"] ===
                          "Lender Transfer" && (
                          <>
                            <RadioGroupControl
                              radioGroupProps={{ style: { fontSize: "40px" } }}
                              label="Is the Appraisal being transferred?"
                              name="Appraisal Transfer"
                              mb={{ base: "5", md: "0" }}
                            >
                              <Radio value={"Yes"}>Yes</Radio>
                              <Radio value={"No"}>No</Radio>
                            </RadioGroupControl>
                          </>
                        )}
                      </Flex>
                    )}

                  {values["Purchase or Refi"] != "Custom" && (
                    <>
                      <Wrap mb={5}>
                        <WrapItem w={{ base: "42%", md: "32%" }}>
                          <InputControl name="First Name" label="First Name" />
                        </WrapItem>
                        <WrapItem w="10%">
                          <InputControl name="MI" label="MI" />
                        </WrapItem>
                        <WrapItem w={{ base: "42%", md: "32%" }}>
                          <InputControl name="Last Name" label="Last Name" />
                        </WrapItem>
                        <WrapItem w={{ base: "48%", md: "20%" }}>
                          <SelectControl
                            name="Location"
                            label="Location"
                            selectProps={{ placeholder: "Select Location" }}
                          >
                            {states.map((state) => {
                              return (
                                <option value={state.code}>{state.name}</option>
                              );
                            })}
                          </SelectControl>
                        </WrapItem>
                      </Wrap>
                      {values["Purchase or Refi"] == "Refi" &&
                        values["Location"] == "TX" && (
                          <Wrap spacing="2%" mb={8}>
                            <WrapItem alignItems="center" h={20}>
                              <RadioGroupControl
                                name="Texas Refi Conversion"
                                label="Please select an option:"
                              >
                                <Radio value="50(a)(6) Cash Out">
                                  50(a)(6) Cash Out
                                </Radio>
                                <Radio value="50(a)(4) Conversion">
                                  50(a)(4) Conversion
                                </Radio>
                                <Radio value="Neither">Neither</Radio>
                              </RadioGroupControl>
                            </WrapItem>
                          </Wrap>
                        )}
                      {values["Purchase or Refi"] != "TBD" && (
                        <Wrap spacing={{ base: "5%", md: "2%" }} mb={5}>
                          <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                            <SelectControl
                              name="Property Type"
                              label="Property Type"
                              selectProps={{
                                placeholder: "Select Property Type",
                              }}
                            >
                              <option value="Manufactured">Manufactured</option>
                              <option value="Single Family">
                                Single Family
                              </option>
                              <option value="Condo">Condo</option>
                              <option value="PUD">PUD</option>
                              <option value="Multi-Unit">Multi-Unit</option>
                            </SelectControl>
                          </WrapItem>
                          <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                            <SelectControl
                              name="Cost for Credit Report"
                              label="Cost for Credit Report"
                              selectProps={{ placeholder: "Select Cost" }}
                            >
                              {costOfCROptions &&
                                costOfCROptions.map((option) => {
                                  return (
                                    <option value={option}>{option}</option>
                                  );
                                })}
                            </SelectControl>
                          </WrapItem>
                          {values["Purchase or Refi"] == "Purchase" ? (
                            <>
                              <WrapItem
                                width={{ base: "43%", md: "23%" }}
                                h={20}
                              >
                                <SelectControl
                                  name="New Construction"
                                  label="New Construction"
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </SelectControl>
                              </WrapItem>
                            </>
                          ) : null}
                          {values["Purchase or Refi"] == "Purchase" && (
                            <>
                              <WrapItem
                                width={{ base: "43%", md: "23%" }}
                                h={20}
                              >
                                <SelectControl
                                  name="First-Time Home Buyer"
                                  label="First-Time Home Buyer"
                                >
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </SelectControl>
                              </WrapItem>
                            </>
                          )}
                        </Wrap>
                      )}
                      {values["Property Type"] == "Manufactured" && (
                        <Wrap spacing="2%" mb={8}>
                          <WrapItem
                            width={{ sm: "100%", md: "33%" }}
                            alignItems="center"
                            h={20}
                          >
                            <RadioGroupControl
                              name="Title Retired"
                              label="Has the title been retired?"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                          {values["Title Retired"] == "No" && (
                            <>
                              <WrapItem
                                width={{ sm: "100%", md: "60%" }}
                                h={20}
                              >
                                <RadioGroupControl
                                  name="Title Allowed To Be Retired After Closing"
                                  label="Does the lender allow for the title to be retired after closing, or it will be retired prior to closing?"
                                >
                                  <Radio value="Yes">Yes</Radio>
                                  <Radio value="No">No</Radio>
                                </RadioGroupControl>
                              </WrapItem>
                              {values[
                                "Title Allowed To Be Retired After Closing"
                              ] == "No" && (
                                <WrapItem>
                                  <Text fontWeight={"bold"} as="em">
                                    This will need to be addressed as not all
                                    lenders allow this to happen post closing
                                  </Text>
                                </WrapItem>
                              )}
                            </>
                          )}
                        </Wrap>
                      )}
                      <Wrap spacing={{ base: "5%", md: "2%" }} mb={5}>
                        {values["Purchase or Refi"] != "HELOC" && (
                          <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                            <SelectControl
                              name="Loan Type"
                              label="Loan Type"
                              selectProps={{ placeholder: "Select Loan Type" }}
                            >
                              {loanTypeOptions &&
                                loanTypeOptions.map((option) => {
                                  return (
                                    <option value={option}>{option}</option>
                                  );
                                })}
                            </SelectControl>
                          </WrapItem>
                        )}
                        {values["Loan Type"] == "Other/Non-QM" ? (
                          <>
                            <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                              <SelectControl
                                name="Other Loan Type"
                                label="Other Loan Type"
                                selectProps={{
                                  placeholder: "Select Loan Type",
                                }}
                              >
                                {otherLoanTypeOptions?.map((option) => {
                                  return (
                                    <option value={option}>{option}</option>
                                  );
                                })}
                              </SelectControl>
                            </WrapItem>
                          </>
                        ) : null}
                        {values["Purchase or Refi"] == "Purchase" ||
                        values["Purchase or Refi"] == "TBD" ||
                        values["Purchase or Refi"] == "HELOC" ? (
                          <>
                            <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                              <InputControl
                                name="Down Payment %"
                                label="Down Payment %"
                              ></InputControl>
                            </WrapItem>
                          </>
                        ) : (
                          <>
                            {values["Purchase or Refi"] == "Refi" ? (
                              <>
                                <WrapItem
                                  width={{ base: "43%", md: "23%" }}
                                  h={20}
                                >
                                  <SelectControl
                                    name="Loan Purpose"
                                    label="Loan Purpose"
                                    selectProps={{
                                      placeholder: "Select Loan Purpose",
                                    }}
                                  >
                                    <option value="Cash Out">Cash Out</option>
                                    <option value="Rate & Term">
                                      Rate & Term
                                    </option>
                                  </SelectControl>
                                </WrapItem>
                                {values["Loan Purpose"] == "Cash Out" ? (
                                  <>
                                    <WrapItem
                                      width={{ base: "43%", md: "23%" }}
                                      h={20}
                                    >
                                      <InputControl
                                        name="Cash Out Amount"
                                        label="Amount"
                                      ></InputControl>
                                    </WrapItem>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        )}
                      </Wrap>
                      {values["Loan Type"] == "FHA" && (
                        <Wrap spacing="2%" mb={8}>
                          <WrapItem
                            width={{ sm: "100%", md: "48%" }}
                            alignItems="center"
                            h={20}
                          >
                            <RadioGroupControl
                              name="Accounted for Seller's Previous Recording Date"
                              label="Have you taken the Seller's previous Recording date into account?"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                          <WrapItem width={{ sm: "100%", md: "48%" }} h={20}>
                            <RadioGroupControl
                              name="Subject to FHA Anti-Flipping Rules"
                              label="Is this loan subject to FHA's Anti-Flipping Rules?"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                        </Wrap>
                      )}
                      {(values["Loan Type"] == "VA" ||
                        values["Loan Type"] == "FHA") && (
                        <>
                          <Wrap spacing="2%" mb={3}>
                            <WrapItem alignItems="center" h={20}>
                              <RadioGroupControl
                                name="Community Property State"
                                label="Is this a Community Property State?"
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </RadioGroupControl>
                            </WrapItem>
                            {values["Community Property State"] == "Yes" && (
                              <WrapItem alignItems="center" h={20}>
                                <RadioGroupControl
                                  name="NBS Liabilities Accounted For"
                                  label="Have the NBS` liabilities been accounted for?"
                                >
                                  <Radio value="Yes">Yes</Radio>
                                  <Radio value="No">No</Radio>
                                </RadioGroupControl>
                              </WrapItem>
                            )}
                          </Wrap>
                        </>
                      )}
                      <Wrap spacing={{ base: "5%", md: "2%" }} mb={5}>
                        <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                          <InputControl
                            name="Target Rate"
                            label="Target Rate"
                          ></InputControl>
                        </WrapItem>
                        {values["Purchase or Refi"] != "HELOC" && (
                          <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                            <InputControl
                              name="Term"
                              label="Term"
                            ></InputControl>
                          </WrapItem>
                        )}
                        <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                          <SelectControl
                            name="Target Lender"
                            label="Target Lender"
                            selectProps={{ placeholder: "Select Lender" }}
                          >
                            {lenders
                              ?.filter((lender) => lender["Core Lender"])
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((lender, i) => (
                                <option
                                  key={`target-lender-option-${lender.id}`}
                                  value={lender.name}
                                >
                                  {lender.name}
                                </option>
                              ))}
                            <option value="Other">Other</option>
                          </SelectControl>
                        </WrapItem>
                        {values["Target Lender"] == "UWM" && (
                          <>
                            <WrapItem
                              width={{ base: "43%", md: "23%" }}
                              h={20}
                              ml={5}
                            >
                              <Text as="em" fontSize="sm">
                                Reminder - An Exact Rate helps with our Ultimate
                                Submissions and Lender Points
                              </Text>
                            </WrapItem>
                          </>
                        )}
                        {values["Target Lender"] == "Other" ? (
                          <>
                            <WrapItem
                              width={{ base: "43%", md: "23%" }}
                              h={20}
                              ml={5}
                            >
                              <SelectControl
                                name="Other Lender"
                                label="Other Lender"
                                selectProps={{ placeholder: "Select Lender" }}
                              >
                                {lenders
                                  ?.filter((lender) => !lender["Core Lender"])
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((lender, i) => (
                                    <option
                                      key={`other-lender-option-${lender.id}`}
                                      value={lender.id}
                                    >
                                      {lender.name}
                                    </option>
                                  ))}
                                {/* {otherLenderOptions?.sort().map((option, i) => 
                                    <option key={`other-lender-option-${i}`} value={option}>{option}</option>
                                )} */}
                              </SelectControl>
                            </WrapItem>
                          </>
                        ) : null}
                      </Wrap>
                      <Wrap
                        spacing={{ base: "5%", md: "2%" }}
                        mb={{ base: 3, md: 5 }}
                      >
                        <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                          <InputControl
                            name="Loan Amount"
                            label="Loan Amount"
                          ></InputControl>
                        </WrapItem>
                        {values["Purchase or Refi"] != "HELOC" && (
                          <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                            <SelectControl
                              name="Waive UW Fee"
                              label="Waive UW Fee"
                              selectProps={{ placeholder: "Yes or No" }}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </SelectControl>
                          </WrapItem>
                        )}
                        {values["Purchase or Refi"] != "TBD" &&
                          values["Purchase or Refi"] != "HELOC" && (
                            <>
                              <WrapItem
                                width={{ base: "43%", md: "23%" }}
                                h={20}
                              >
                                <SelectControl
                                  name="Order Appraisal"
                                  label="Order Appraisal"
                                  selectProps={{ placeholder: "Yes or No" }}
                                >
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </SelectControl>
                              </WrapItem>
                              {values["Purchase or Refi"] != "HELOC" && (
                                <WrapItem
                                  width={{ base: "43%", md: "23%" }}
                                  h={20}
                                >
                                  <DatePickerField
                                    name="Closing Date"
                                    label="Closing Date"
                                    className={datepickerClass}
                                  />
                                </WrapItem>
                              )}
                            </>
                          )}
                      </Wrap>
                      <Wrap
                        spacing={{ base: "5%", md: "2%" }}
                        mb={{ base: 3, md: 5 }}
                      >
                        <WrapItem width={{ base: "43%", md: "23%" }} h={20}>
                          <InputControl
                            name="Credit Score"
                            label="Credit Score"
                          ></InputControl>
                        </WrapItem>
                      </Wrap>
                      {values["Purchase or Refi"] != "TBD" && (
                        <Wrap spacing="2%" mb={12}>
                          <WrapItem
                            width={{ base: "100%", md: "23%" }}
                            alignItems="center"
                            h={20}
                          >
                            <RadioGroupControl name="LP or BP">
                              <Stack direction="column">
                                <Radio value="LP">Lender Paid</Radio>
                                <Radio value="BP">Borrower Paid</Radio>
                              </Stack>
                            </RadioGroupControl>
                          </WrapItem>
                          <WrapItem width={{ base: "100%", md: "23%" }} h={20}>
                            <RadioGroupControl
                              name="POS Docs Received"
                              label="POS Docs Received"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                          <WrapItem width={{ base: "100%", md: "23%" }} h={20}>
                            <RadioGroupControl
                              name="Mail Away/POA"
                              label="Mail Away/POA"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>

                          {values["Purchase or Refi"] == "Purchase" && (
                            <>
                              <WrapItem
                                width={{ sm: "100%", md: "23%" }}
                                h={20}
                              >
                                <Stack
                                  direction={{ base: "row", md: "column" }}
                                >
                                  <InputControl
                                    name="Anticipated Funds to Close"
                                    labelProps={{ fontSize: "15px" }}
                                    label="Anticipated Funds to Close"
                                  ></InputControl>
                                </Stack>
                              </WrapItem>
                            </>
                          )}
                        </Wrap>
                      )}
                      {values["LP or BP"] == "BP" && (
                        <>
                          <Wrap mb={7}>
                            <SelectControl
                              name="Reason for BP"
                              label="Reason for Borrower Paid"
                              w={{ base: "100%", md: "45%" }}
                              selectProps={{ placeholder: "Select a reason" }}
                            >
                              <option value="Service Issue">
                                Service Issue
                              </option>
                              <option value="Competitive Scenario">
                                Competitive Scenario
                              </option>
                              <option value="Compliance">Compliance</option>
                            </SelectControl>
                            {values["Reason for BP"] ==
                              "Competitive Scenario" && (
                              <WrapItem width={{ base: "100%", md: "45%" }}>
                                <InputControl
                                  name="Competitor"
                                  label="Who is the competitor?"
                                ></InputControl>
                              </WrapItem>
                            )}
                          </Wrap>
                        </>
                      )}
                      <Wrap spacing="2%" mb={3}>
                        {values["Purchase or Refi"] != "TBD" && (
                          <WrapItem width={{ base: "100%", md: "35%" }} h={20}>
                            <RadioGroupControl
                              name="Priced Loan Today"
                              label="Did you price this loan today?"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                        )}
                        {values["Purchase or Refi"] == "Refi" && (
                          <WrapItem width={{ base: "100%", md: "35%" }} h={20}>
                            <RadioGroupControl
                              name="Ownership/Vesting Changing"
                              label="Is Ownership/Vesting Changing?"
                            >
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </RadioGroupControl>
                          </WrapItem>
                        )}
                      </Wrap>
                      {values["Ownership/Vesting Changing"] &&
                        values["Ownership/Vesting Changing"] == "Yes" && (
                          <Text fontWeight={"bold"} mb="4">
                            Changes to Ownership/Vesting will usually incur
                            higher Title costs. Please ensure these higher costs
                            have been accounted for
                          </Text>
                        )}
                      {(values["Purchase or Refi"] == "Purchase" ||
                        values["Purchase or Refi"] == "Refi" ||
                        values["Purchase or Refi"] == "HELOC") && (
                        <>
                          <Wrap spacing="2%" mb={3}>
                            <WrapItem
                              width={{ base: "100%", md: "35%" }}
                              h={20}
                            >
                              <RadioGroupControl
                                name="Are Escrows Being Waived?"
                                label="Are Escrows Being Waived?"
                              >
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </RadioGroupControl>
                            </WrapItem>

                            {values["Are Escrows Being Waived?"] == "Yes" && (
                              <WrapItem
                                width={{ base: "100%", md: "35%" }}
                                h={20}
                              >
                                <RadioGroupControl
                                  name="Escrows Waived"
                                  label="Escrows Waived"
                                >
                                  <Radio value="Taxes">Taxes</Radio>
                                  <Radio value="Insurance">Insurance</Radio>
                                  <Radio value="Both">Both</Radio>
                                </RadioGroupControl>
                              </WrapItem>
                            )}
                          </Wrap>
                        </>
                      )}

                      {values["Purchase or Refi"] != "TBD" && (
                        <Wrap spacing="2%" mb={8}>
                          <WrapItem alignItems="center" h={20}>
                            <CheckboxControl
                              name="Opt Out Of HOI Quote"
                              label="Opt Out Of HOI Quote"
                              defaultChecked={values["Opt Out Of HOI Quote"]}
                            ></CheckboxControl>
                          </WrapItem>
                          {values["Opt Out Of HOI Quote"] && (
                            <>
                              <WrapItem
                                width={{ sm: "100%", md: "48%" }}
                                h={20}
                              >
                                <InputControl
                                  name="HOI Agent"
                                  label="HOI Agent"
                                ></InputControl>
                              </WrapItem>
                            </>
                          )}
                        </Wrap>
                      )}
                      {values["Loan Type"] == "VA" && (
                        <>
                          <Heading
                            mb={1}
                            mr={2}
                            fontStyle="italic"
                            textAlign={"center"}
                            size="sm"
                          >
                            Nearest Living Relative
                          </Heading>
                          <Box
                            border={"1px"}
                            p={2}
                            borderRadius={"lg"}
                            mb={10}
                            borderColor={adaptiveGreyBorder}
                          >
                            <Tooltip
                              placement="top-start"
                              background="none"
                              label={
                                <Box width="750px" border="5px solid lightgrey">
                                  <Image
                                    src={require("../../../src/assets/img/NearestLivingRelativeArive.png")}
                                  ></Image>
                                </Box>
                              }
                            >
                              <Box mt={2} mb={2}>
                                <CheckboxControl
                                  defaultChecked={
                                    values["Relative Entered Into Arive"]
                                  }
                                  name="Relative Entered Into Arive"
                                  label="Entered Into Arive"
                                ></CheckboxControl>
                              </Box>
                            </Tooltip>

                            <Wrap spacing={3}>
                              <WrapItem w={{ base: "100%", md: "32%" }}>
                                <InputControl
                                  name="Relative.Name"
                                  label="Name"
                                  isDisabled={
                                    values["Relative Entered Into Arive"]
                                  }
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                              <WrapItem w={{ base: "100%", md: "32%" }}>
                                <InputControl
                                  name="Relative.Phone"
                                  label="Phone Number"
                                  isDisabled={
                                    values["Relative Entered Into Arive"]
                                  }
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                              <WrapItem w={{ base: "100%", md: "31%" }}>
                                <InputControl
                                  name="Relative.Relation"
                                  label="Relation"
                                  isDisabled={
                                    values["Relative Entered Into Arive"]
                                  }
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                              <WrapItem w="100%">
                                <InputControl
                                  name="Relative.Address"
                                  label="Address"
                                  isDisabled={
                                    values["Relative Entered Into Arive"]
                                  }
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                            </Wrap>
                          </Box>
                        </>
                      )}
                      {(values["Purchase or Refi"] == "Refi" ||
                        values["Purchase or Refi"] == "Purchase") && (
                        <>
                          <Heading
                            mb={1}
                            mr={2}
                            fontStyle="italic"
                            textAlign={"center"}
                            size="sm"
                          >
                            Appraisal Contact (optional)
                          </Heading>
                          <Box
                            border={"1px"}
                            p={2}
                            borderRadius={"lg"}
                            mb={10}
                            borderColor={adaptiveGreyBorder}
                          >
                            <Wrap spacing={3}>
                              <WrapItem w={{ base: "100%", md: "32%" }}>
                                <InputControl
                                  name="Appraisal Contact.Name"
                                  label="Name"
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                              <WrapItem w={{ base: "100%", md: "32%" }}>
                                <InputControl
                                  name="Appraisal Contact.Phone"
                                  label="Phone Number"
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                              <WrapItem w={{ base: "100%", md: "31%" }}>
                                <InputControl
                                  name="Appraisal Contact.Email"
                                  label="Email"
                                  mb={3}
                                ></InputControl>
                              </WrapItem>
                            </Wrap>
                          </Box>
                        </>
                      )}
                      {values["Purchase or Refi"] != "TBD" && (
                        <>
                          <Heading
                            mb={2}
                            mt={2}
                            textAlign={{ base: "left", md: "center" }}
                            fontStyle="italic"
                            size="sm"
                          >
                            Title Company
                          </Heading>

                          <Box
                            border={"1px"}
                            p={3}
                            mt={2}
                            mb={12}
                            borderRadius={"lg"}
                            borderColor={adaptiveGreyBorder}
                          >
                            <Wrap spacing="2%" mb={{ base: 0, md: 5 }}>
                              <WrapItem
                                width={{ sm: "100%", md: "48%" }}
                                h={20}
                              >
                                <InputControl
                                  name="Title Company Details.Name"
                                  label="Name"
                                ></InputControl>
                              </WrapItem>
                              <WrapItem
                                width={{ sm: "100%", md: "48%" }}
                                h={20}
                              >
                                <InputControl
                                  name="Title Company Details.Point of Contact"
                                  label="Point of Contact"
                                ></InputControl>
                              </WrapItem>
                            </Wrap>
                            <Wrap spacing="2%">
                              <WrapItem
                                width={{ sm: "100%", md: "48%" }}
                                h={20}
                              >
                                <InputControl
                                  name="Title Company Details.Phone"
                                  label={
                                    action == "Add"
                                      ? "Phone Number (optional)"
                                      : "Phone Number"
                                  }
                                  labelProps={
                                    action == "Add" && {
                                      color: adaptiveGreyText,
                                    }
                                  }
                                ></InputControl>
                              </WrapItem>
                              <WrapItem
                                width={{ sm: "100%", md: "48%" }}
                                h={20}
                              >
                                <InputControl
                                  name="Title Company Details.Email"
                                  label={
                                    action == "Add"
                                      ? "Email (optional)"
                                      : "Email"
                                  }
                                  labelProps={
                                    action == "Add" && {
                                      color: adaptiveGreyText,
                                    }
                                  }
                                ></InputControl>
                              </WrapItem>
                            </Wrap>
                          </Box>
                        </>
                      )}
                      <Wrap
                        spacing={{ base: "5%", xl: "2%" }}
                        mb={{ base: 3, xl: 5 }}
                      >
                        <WrapItem width={{ base: "90%", xl: "30%" }}>
                          <SelectControl
                            name="Why did you select this Lender?"
                            label="Why did you select this Lender?"
                            selectProps={{ placeholder: "Select Reason" }}
                          >
                            {lenderReasonOptions?.map((option, i) => (
                              <option
                                key={`lender-reason-option-${i}`}
                                value={option}
                              >
                                {option}
                              </option>
                            ))}
                          </SelectControl>
                        </WrapItem>
                        {values["Why did you select this Lender?"] &&
                          values["Why did you select this Lender?"] ==
                            "Other" && (
                            <WrapItem width={{ base: "90%", xl: "45%" }}>
                              <InputControl
                                name="(Other) Why did you select this Lender?"
                                label="&nbsp;"
                                inputProps={{ placeholder: "Please Specify" }}
                              ></InputControl>
                            </WrapItem>
                          )}
                      </Wrap>
                      {action == "Submit" && (
                        <>
                          <Wrap
                            spacing={{ base: "5%", xl: "2%" }}
                            mb={{ base: 3, xl: 5 }}
                          >
                            <WrapItem width={{ base: "45%", xl: "23%" }} h={20}>
                              <SelectControl
                                name="Lender"
                                label="Lender"
                                onChange={(e) => {
                                  const selectedLender = lenders.find(
                                    (lender) => lender.name === e.target.value
                                  );
                                  if (selectedLender?.id) {
                                    setFieldValue(
                                      "lenderID",
                                      selectedLender?.id
                                    );
                                  } else setFieldValue("lenderID", "Other");
                                }}
                                selectProps={{ placeholder: "Select Lender" }}
                              >
                                {lenders
                                  ?.sort((a, b) => a.name.localeCompare(b.name))
                                  .map((lender, i) => (
                                    <option
                                      key={`target-lender-option-${lender.id}`}
                                      value={lender.name}
                                    >
                                      {lender.name}
                                    </option>
                                  ))}
                                <option value="Other">Other</option>
                              </SelectControl>
                            </WrapItem>
                            <WrapItem width={{ base: "45%", xl: "23%" }} h={20}>
                              <SelectControl
                                name="Product"
                                label="Product"
                                selectProps={{ placeholder: "Select Product" }}
                              >
                                {productOptions?.sort().map((product, i) => {
                                  return (
                                    <option
                                      key={`product-option-${i}`}
                                      value={product}
                                    >
                                      {product}
                                    </option>
                                  );
                                })}
                              </SelectControl>
                            </WrapItem>
                            <WrapItem width={{ base: "45%", xl: "23%" }} h={20}>
                              <InputControl
                                name="Loan #"
                                label="Loan #"
                              ></InputControl>
                            </WrapItem>
                            <WrapItem width={{ base: "45%", xl: "23%" }} h={20}>
                              <InputControl
                                name="HOI Quote/Amount"
                                label="HOI Quote/Amount"
                              ></InputControl>
                            </WrapItem>
                          </Wrap>
                          <Wrap
                            spacing={{ base: "5%", xl: "2%" }}
                            mb={{ base: 3, xl: 5 }}
                          >
                            <WrapItem width={{ base: "45%", xl: "23%" }} h={20}>
                              <InputControl
                                name="Rate"
                                label="Rate"
                              ></InputControl>
                            </WrapItem>
                            <WrapItem
                              width={{ base: "45%", xl: "23%" }}
                              alignItems={"center"}
                              h={20}
                            >
                              <RadioGroupControl name="Floated or Locked">
                                <Radio value="Floated">Floated</Radio>
                                <Radio value="Locked">Locked</Radio>
                              </RadioGroupControl>
                            </WrapItem>

                            {values["LP or BP"] == "BP" && (
                              <WrapItem
                                width={{ base: "45%", xl: "23%" }}
                                alignItems={"center"}
                                h={20}
                              >
                                <CheckboxSingleControl name="BP Confirmed">
                                  <option value={true}>Confirm BP</option>
                                </CheckboxSingleControl>
                              </WrapItem>
                            )}
                            <WrapItem width={{ base: "45%", md: "23%" }} h={20}>
                              <DatePickerField
                                name="App Date"
                                label="App Date"
                                className={datepickerClass}
                                dontRestrictDates
                              />
                            </WrapItem>
                          </Wrap>
                          {values["Target Lender"] == "UWM" && (
                            <Wrap
                              spacing={{ base: "5%", xl: "2%" }}
                              mb={{ base: 3, xl: 5 }}
                            >
                              <WrapItem width={{ base: "90%", xl: "40%" }}>
                                <CheckboxSingleControl
                                  name="Asked the LO about an Exact Rate"
                                  defaultChecked={
                                    values["Asked the LO about an Exact Rate"]
                                  }
                                >
                                  <option value={true}>
                                    Asked the LO about an Exact Rate
                                  </option>
                                </CheckboxSingleControl>
                              </WrapItem>
                              <WrapItem width={{ base: "45%", xl: "25%" }}>
                                <RadioGroupControl
                                  name="W2 Income"
                                  label="W2 Income"
                                  defaultValue={values["W2 Income"]}
                                >
                                  <Radio value={"Yes"}>Yes</Radio>
                                  <Radio value={"No"}>No</Radio>
                                </RadioGroupControl>
                              </WrapItem>
                              {values["W2 Income"] &&
                                values["W2 Income"] == "Yes" && (
                                  <WrapItem width={{ base: "45%", xl: "25%" }}>
                                    <CheckboxSingleControl
                                      name="VOE fee disclosed"
                                      defaultChecked={
                                        values["VOE fee disclosed"]
                                      }
                                    >
                                      <option value={true}>
                                        VOE fee disclosed
                                      </option>
                                    </CheckboxSingleControl>
                                  </WrapItem>
                                )}
                            </Wrap>
                          )}
                          {values["Loan Type"] == "USDA" && (
                            <VStack
                              spacing={{ base: "5%", md: "2%" }}
                              mb={{ base: 3, md: 5 }}
                            >
                              <CheckboxSingleControl
                                name="GUS has been completed"
                                defaultChecked={
                                  values["GUS has been completed"]
                                }
                              >
                                <option value={true}>
                                  GUS has been completed
                                </option>
                              </CheckboxSingleControl>
                              <CheckboxSingleControl
                                name="Findings have been released to the Lender"
                                defaultChecked={
                                  values[
                                    "Findings have been released to the Lender"
                                  ]
                                }
                              >
                                <option value={true}>
                                  Findings have been released to the Lender
                                </option>
                              </CheckboxSingleControl>
                            </VStack>
                          )}
                          <VStack
                            spacing={{ base: "5%", md: "2%" }}
                            mb={{ base: 3, md: 5 }}
                          >
                            <RadioGroupControl
                              name="Income Analysis Completed"
                              label="Income Analysis Completed"
                              defaultValue={values["Income Analysis Completed"]}
                            >
                              <Radio value={"Yes"}>Yes</Radio>
                              <Radio value={"No"}>No</Radio>
                            </RadioGroupControl>
                            {values["Income Analysis Completed"] == "No" && (
                              <TextareaControl
                                name="Income Analysis Reason"
                                label="Why?"
                              />
                            )}
                          </VStack>
                          <TextareaControl
                            name="What POS Docs have been received"
                            label="What POS Docs have been received?"
                          />
                          <Box mb="3"></Box>
                          <TextareaControl
                            name="What POS Docs are outstanding"
                            label="What POS Docs are outstanding?"
                          />
                          <Box mb="3"></Box>

                          <Wrap
                            spacing={{ base: "5%", md: "2%" }}
                            mb={{ base: 3, md: 5 }}
                          >
                            <WrapItem w="100%" h={20}>
                              <InputControl
                                name="Comp Code"
                                label="Comp Code"
                              ></InputControl>
                            </WrapItem>
                          </Wrap>
                        </>
                      )}
                    </>
                  )}

                  {Level1 &&
                    action == "Add" &&
                    values["Purchase or Refi"] != "Custom" && (
                      <>
                        <Text fontWeight={"semibold"} as="em">
                          Please help us to set this file up for success. The
                          better notes and clarity, the better we can all
                          execute together as a team. A good plan is to
                          copy/paste your notes from the Loan Narrative in
                          Arive.
                        </Text>
                        <Divider mt="2" mb="2" />
                        <TextareaControl
                          name="Loan Narrative"
                          label="Loan Narrative"
                          marginBottom="2"
                          textareaProps={{
                            minHeight: "125px",
                            placeholder:
                              "Tell the story. What are the clients trying to accomplish?",
                          }}
                        />
                        <TextareaControl
                          name="Assets and Income"
                          label="Assets and Income"
                          marginBottom="2"
                          textareaProps={{
                            minHeight: "125px",
                            placeholder:
                              "Any issues with either of these? Where are the funds for closing coming from - do the statements we have in Arive support the cash to close. If not, where are they coming from? Omitted liabilities are they paid in full or being paid at closing.",
                          }}
                        />
                        <TextareaControl
                          name="Gotchas"
                          label="Gotchas"
                          marginBottom="2"
                          textareaProps={{
                            minHeight: "125px",
                            placeholder:
                              "REO`s retained or being sold. Do you have any concerns about this file that we should be aware of? What are the best times to reach your client?",
                          }}
                        />
                      </>
                    )}

                  {action == "Add" &&
                    values["Purchase or Refi"] != "Custom" && (
                      <TextareaControl
                        name="Notes"
                        label="Notes"
                        textareaProps={{ minHeight: "125px" }}
                      />
                    )}
                  {values["Purchase or Refi"] == "Custom" && (
                    <TextareaControl
                      name="Description"
                      label="Describe your request"
                    />
                  )}
                  {values && currentUser?.displayName == "Brian Phelan" && (
                    <Text>{JSON.stringify(values)}</Text>
                  )}
                  {errors && currentUser?.displayName == "Brian Phelan" && (
                    <Text mt="2">{JSON.stringify(errors)}</Text>
                  )}
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  {action == "Add" ? (
                    <>
                      <ResetButton mr={10}>Reset</ResetButton>
                      <SubmitButton alignSelf={"flex-end"}>Add</SubmitButton>
                    </>
                  ) : (
                    <>
                      <Button mr={10} onClick={() => onEdit(values)}>
                        Save
                      </Button>

                      {props.status == "In Progress" && (
                        <ParkingReasonPrompt values={values} onEdit={onEdit} />
                      )}
                      {props.status != "Not Submitted" && (
                        <NotSubmittedReasonPrompt
                          values={values}
                          onEdit={onEdit}
                        />
                      )}
                      {props.status != "TBD" && (
                        <Button
                          mr={6}
                          colorScheme="blue"
                          onClick={() => onEdit(values, "TBD")}
                        >
                          TBD
                        </Button>
                      )}
                      {props.status != "In Progress" && (
                        <Button
                          mr={6}
                          colorScheme="orange"
                          onClick={() => onEdit(values, "In Progress")}
                        >
                          In Progress
                        </Button>
                      )}
                      {props.status != "Completed" && (
                        <SubmitButton
                          fontSize={{ base: "xs", md: "md" }}
                          whiteSpace={"normal"}
                          alignSelf={"flex-end"}
                        >
                          Submit to Processing
                        </SubmitButton>
                      )}
                    </>
                  )}
                </DrawerFooter>
              </DrawerContent>
            </Box>
          )}
        </Formik>
      </Drawer>
    </>
  );
}

function ParkingReasonPrompt({ onEdit, values }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState();
  const [otherReason, setOtherReason] = useState("");
  return (
    <>
      <Button mr={6} colorScheme="yellow" onClick={onOpen}>
        Park
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select A Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup mb={2} onChange={setReason} value={reason}>
              <Flex flexWrap={"wrap"} flexDir="column">
                <Radio value="Loan Structure Questions">
                  Loan Structure Questions
                </Radio>
                <Radio value="Income/DTI">Income/DTI</Radio>
                <Radio value="Assets">Assets</Radio>
                <Radio value="Non-QM/Disclosures">Non-QM/Disclosures</Radio>
                <Radio value="Other">Other</Radio>
              </Flex>
            </RadioGroup>
            {reason && reason.includes("Other") && (
              <Input
                placeholder="Other reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              ></Input>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              disabled={!reason || (reason == "Other" && otherReason == "")}
              colorScheme="green"
              onClick={() => onEdit(values, "Parked", reason, otherReason)}
            >
              Park Loan
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function NotSubmittedReasonPrompt({ onEdit, values }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState();
  const [otherReason, setOtherReason] = useState("");
  return (
    <>
      <Button
        fontSize={{ base: "xs", md: "md" }}
        whiteSpace={"normal"}
        mr={6}
        colorScheme="purple"
        onClick={onOpen}
      >
        Not Submitted
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select A Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup mb={2} onChange={setReason} value={reason}>
              <Flex flexWrap={"wrap"} flexDir="column">
                <Radio value="Loan Structure Questions">
                  Loan Structure Questions
                </Radio>
                <Radio value="Guidelines">Guidelines</Radio>
                <Radio value="DTI">DTI</Radio>
                <Radio value="Non-Responsive Borrower">
                  Non-Responsive Borrower
                </Radio>
                <Radio value="Other">Other</Radio>
              </Flex>
            </RadioGroup>
            {reason && reason.includes("Other") && (
              <Input
                placeholder="Other reason"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              ></Input>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              disabled={!reason || (reason == "Other" && otherReason == "")}
              colorScheme="purple"
              onClick={() =>
                onEdit(values, "Not Submitted", reason, otherReason)
              }
            >
              Send To Not Submitted
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
