import { useEffect } from "react";
import AppRouter from "./components/Navigation/AppRouter";
import AuthContextProvider, { useAuth } from "./contexts/AuthContext";
import LoadingPage from "./pages/LoadingPage";
export var deferredPrompt;
export var installable = false;
export const setInstallable = (val) => {
  installable = val;
};

export const handleInstallClick = (e) => {
  // Hide the app provided install promotion
  setInstallable(false);
  // Show the install prompt
  deferredPrompt.prompt();
  // Wait for the user to respond to the prompt
  deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === "accepted") {
      console.log("User accepted the install prompt");
    } else {
      console.log("User dismissed the install prompt");
    }
  });
};

function App(props) {
  const { loading } = useAuth();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
  }, []);

  if (loading) {
    return <LoadingPage />;
  } else {
    return (
      // <AuthProvider>
      //   <UserProvider>
      //     <NotificationProvider>
      //       <MarketingProvider>
      //         <VersionProvider>
      //         </VersionProvider>
      //       </MarketingProvider>
      //     </NotificationProvider>
      //   </UserProvider>
      // </AuthProvider>

      <AuthContextProvider>
        <AppRouter />
      </AuthContextProvider>
    );
  }
}

export default App;
